// メインコンテンツ（content-mainコンポーネント）の内容はCMSなどで管理されるHTMLを含んだり、
// ページ独自のスタイルを扱うことが多いので例外的に専用のルールを設ける。
//
// メインコンテンツのエレメントは基本的に自由である。
// ただし、クラス名は`c-`で開始してはいけない。もちろん 【`c-` + `コンポーネント名` + `__` + `エレメント名`】 という規則でクラス名をつけない。
// さらにそのルールに従って、メインコンテンツの中にコンポーネントを内包してはいけない。
//
// 🍔 マークは BurgerEditor専用の意味で、利用しない場合は削除する
//
// stylelint-disable selector-class-pattern
//
.bge-contents, // 🍔
.c-content-main {
	width: 1000px;
	margin: 0 auto;
	// 🍔 全ブロック基本の余白
	$bgb-default-margin-bottom: 2.5em;
	// 🍔 背景色・枠線がある場合のパディング
	$bgb-opt-padding: .8em;
	@media (--xs) {
		width: auto;
		padding: 0 15px;
	}

	//
	// 基本スタイル
	//
	font-size: 1.6rem;
	line-height: 1.75;

	@media (--xs) {
		font-size: 12px; // iPhone6でおよそ16px
	}

	//
	// エレメント
	//
	// stylelint-disable selector-nested-pattern
	// ☝ 以降のフォントサイズの指定単位は em を推奨
	//
	a {
		color: currentColor;

		&[href^="tel"] {
			pointer-events: none;
			text-decoration: none;

			@media (--xs) {
				pointer-events: auto;
				text-decoration: underline;
			}
		}
	}

	h2 {
		font-size: 30px;
		line-height: 1.65;
		margin: 0 0 22px;
		letter-spacing: -1.35px;
		padding: 0 0 0 52px;
		font-family: $font-family-mincho;
		font-weight: bold;
		color: #CC001B;
		position: relative;
		overflow: hidden;
		@media (--xs) {
			font-size: 18px;
			line-height: 1.4;
			padding: 0 0 0 30px;
		}

		&::before {
			content: "";
			width: 45px;
			height: 24px;
			display: block;
			background: url("../img/common/icon-h1.png") no-repeat right bottom;
			position: absolute;
			left: -1px;
			top: 10px;
			z-index: -1;
			@media (--xs) {
				width: 23px;
				height: 12px;
				top: 5px;
				background-size: 23px auto;
			}
		}

		&:first-child {
			margin-top: 0;
		}
	}

	// 🍔
	.bge-title-h2 {
		margin-top: 0;
	}

	h3 {
		background: url("../img/common/bg-h2.png");
		font-family: $font-family-mincho;
		font-size: 24px;
		padding: 12px 19px 9px;
		color: #000;
		line-height: 1.65;
		margin: 2.2em 0 1.1em;
		letter-spacing: -.9px;
		@media (--xs) {
			font-size: 16px;
			padding: 8px 12px;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	// 🍔
	.bge-title-h3 {
		margin-top: 0;
	}

	h4 {
		font-size: 20px;
		color: #E90D2A;
		font-family: $font-family-mincho;
		line-height: 1.65;
		padding: 0;
		margin: 1em 0 1em -1px;
		letter-spacing: -.8px;
		@media (--xs) {
			font-size: 15px;
			background-size: 20px auto;
			padding: 0;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	h5 {
		font-size: 18px;
		line-height: 1.65;
		font-family: $font-family-mincho;
		margin: 0 0 1em -1px;
		padding: 0 5px 3px 0;
		color: #000;
		letter-spacing: -.8px;
		display: inline-block;
		background: url("../img/common/bg-h4.png") repeat-x left bottom;
		@media (--xs) {
			font-size: 14px;
			padding-bottom: 3px;
			background-size: 4px auto;
		}

		&:first-child {
			margin-top: 0;
		}

		& + ul {
			margin-top: 0;
		}
	}

	h6 {
		font-size: 20px;
		font-family: $font-family-mincho;
		line-height: 1.65;
		letter-spacing: 1.4px;
		font-feature-settings : "palt";
		margin: .65em 0 1.15em;
		color: #000;
		@media (--xs) {
			font-size: 15px;
		}

		&:first-child {
			margin-top: 0;
		}

		& + p {
			margin-top: -.9em;
			@media (--xs) {
				margin-top: -.5em;
			}
		}
	}

	p {
		letter-spacing: -.35px;
	}

	img {
		max-width: 100%;
		vertical-align: bottom;
	}

	ul {
		letter-spacing: -.45px;
		padding-left: 0;

		> li {
			list-style: none;
			padding-left: 17px;
			position: relative;
			padding: 5px 0 3px 17px;
			@media (--xs) {
				padding: 3px 0 2px 17px;
			}

			&::before {
				content: "";
				width: 12px;
				height: 12px;
				display: block;
				background: url("../img/common/icon-ul.png") no-repeat;
				position: absolute;
				left: 0;
				top: 13px;
				@media (--xs) {
					top: 7px;
				}
			}
		}

		&.link-list {
			letter-spacing: .8px;

			li {
				padding: 0 0 12px;
				@media (--xs) {
					padding: 0 0 6px;
				}

				&::before {
					display: none;
				}

				a {
					&::after {
						content: "";
						width: 5px;
						height: 9px;
						display: inline-block;
						background: url("../img/common/icon-link.png") no-repeat;
						margin-left: 12px;
						position: relative;
						top: 1px;
					}

					&.icon-external::after {
						width: 16px;
						height: 12px;
						background: url("../img/common/icon-gaibu-02.png") no-repeat;
						margin-left: 5px;
					}

					&.icon-pdf::after {
						width: 12px;
						height: 14px;
						background: url("../img/common/icon-pdf.png") no-repeat;
						margin-left: 6px;
					}
				}
			}
		}
	}

	ol {
		counter-reset: content-main-ol-default;
		padding-left: 0;

		> li {
			list-style: none;

			&::before {
				min-width: 1.9em;
				display: inline-block;
				content: counter(content-main-ol-default, decimal-leading-zero) ". ";
				counter-increment: content-main-ol-default;
				font-family: $font-family-eng;
				font-weight: 600;
				color: #E90D2A;
				letter-spacing: .8px;
			}

			&.c-pagination__number:before {
			    display: none;
			}
		}

		ul,
		ol {
			// Lv.2以降
			margin-left: $indent-sub-list;
		}
	}

	.local-nav {
		background: url("../img/common/bg-lnav.png");
		margin: 3.9em 0 1.9em;
		padding: 19px 49px 19px 50px;
		@media (--xs) {
			margin: 2em 0;
			padding: 1px 20px;
			background-size: 4px auto;
		}

		ul {
			overflow: hidden;
			margin-left: -30px;
			@media (--xs) {
				margin-left: 0;
			}
		}

		li {
			width: 280px;
			float: left;
			padding: 0;
			margin: .5em 0 .5em 30px;
			@media (--xs) {
				float: none;
				width: auto;
				margin: .8em 0;
			}

			&::before {
				display: none;
			}

			a {
				display: block;
				border: solid 1px #CC001B;
				background: #FFF;
				text-align: center;
				font-size: 16px;
				font-weight: bold;
				text-decoration: none;
				color: $primary-color;
				padding: 21px 30px 19px 40px;
				letter-spacing: .2px;
				position: relative;
				-webkit-transition: .3s;
				transition: .3s;
				@media (--xs) {
					font-size: 13px;
					padding: 15px 30px 15px 40px;
				}

				&::after {
					content: "";
					width: 8px;
					height: 18px;
					display: block;
					background: url("../img/common/icon-arrow-lnav.png") no-repeat;
					position: absolute;
					right: 24px;
					top: 50%;
					margin-top: -9px;
					@media (--xs) {
						width: 5px;
						height: 11px;
						background-size: 5px auto;
						right: 12px;
						margin-top: -5px;
					}
				}

				&:hover {
					opacity: .5;
				}
			}
		}
	}

	.news-date {
		font-family: $font-family-eng;
		color: #FFF;
		font-size: 14px;
		width: 100px;
		background: #333;
		text-align: center;
		margin: -3px 0 22px;
		padding: 4px 0 2px;
		letter-spacing: .6px;
		@media (--xs) {
			font-size: 13px;
			margin-top: 2px;
		}
	}

	.align-center {
		text-align: center;
	}

	.link-title {
		text-align: center;

		a {
			text-decoration: none;
			border-bottom: solid 1px #CC001B;
			display: block;
			padding: 1px 20px 6px 0;
			position: relative;
			-webkit-transition: .3s;
			transition: .3s;

			&:hover {
				opacity: .5;
			}

			&::after {
				content: "";
				width: 8px;
				height: 18px;
				display: block;
				background: url("../img/common/icon-arrow-01.png") no-repeat;
				position: absolute;
				right: 9px;
				top: 10px;
				@media (--xs) {
					width: 5px;
					height: 11px;
					background-size: 5px auto;
					top: 9px;
				}
			}
		}
	}

	.link-more {
		text-align: center;
		margin: -.25em 0 66px;
		@media (--xs) {
			margin-bottom: 30px;
		}

		&:last-child {
			margin-bottom: 66px;
			@media (--xs) {
				margin-bottom: 30px;
			}
		}

		a {
			display: inline-block;
			font-family: $font-family-eng;
			font-size: 16px;
			color: #E90D2A;
			font-weight: 600;
			text-decoration: none;
			letter-spacing: .2px;
			padding: 0 40px 6px 44px;
			border-bottom: solid 1px $primary-color;
			position: relative;
			-webkit-transition: .3s;
			transition: .3s;
			@media (--xs) {
				font-size: 14px;
				padding: 0 40px 3px;
			}

			&::after {
				content: "";
				width: 4px;
				height: 9px;
				display: block;
				position: absolute;
				bottom: 16px;
				right: 17px;
				background: url("../img/common/icon-more.png") no-repeat;
				@media (--xs) {
					bottom: 10px;
				}
			}

			&:hover {
				opacity: .5;
			}
		}
	}

	table {
		border-collapse: collapse;
		width: 100%;
		margin: 2.15em 0;

		@media (--xs) {
			display: block;
			margin: 1em 0;
		}
	}

	caption {
		font-weight: bold;

		@media (--xs) {
			display: block;
		}
	}

	thead {
		@media (--xs) {
			display: none;
		}
	}

	tfoot {
		@media (--xs) {
			display: none;
		}
	}

	tbody {
		@media (--xs) {
			display: block;
		}
	}

	tr {
		@media (--xs) {
			display: block;
		}
	}

	th,
	td {
		@media (--xs) {
			display: block;
			width: 100% !important; // stylelint-disable-line declaration-no-important
		}
	}

	th {
		background: $primary-color;
		color: $lightest-color;
		text-align: left;
		font-size: 16px;
		border-bottom: solid 1px $lightest-color;
		border-right: solid 10px $lightest-color;
		padding: 14px 10px 12px;
		letter-spacing: .7px;
		@media (--xs) {
			font-size: 14px;
			border-right: none;
			padding: 10px;
		}
	}

	td {
		border-top: solid 1px #DDD;
		border-bottom: solid 1px #DDD;
		padding: 14px 8px 12px;
		letter-spacing: -.2px;
		@media (--xs) {
			padding: 10px;
			border-top: none;
		}
	}

	p {
		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	blockquote {
		background: #F1F1F1;
		margin: 0 0 0 $indent-sub-list;
		padding: 1em 2em;
		position: relative;

		&::before {
			content: "“";
			display: block;
			font-size: 40px;
			left: 0;
			line-height: 0;
			margin-top: .5em;
			opacity: .2;
			pointer-events: none;
			position: absolute;
			top: 0;
			user-select: none;
		}

		&::after {
			bottom: 0;
			content: "”";
			display: block;
			font-size: 40px;
			line-height: 0;
			opacity: .2;
			pointer-events: none;
			position: absolute;
			right: 0;
			user-select: none;
		}
	}

	figure {
		margin: 0;
	}

	figcaption {
		background: none; // stylelint-disable-line declaration-no-important
		font-size: .9em;
		font-weight: normal;
		line-height: 1.6;
		padding: .8em 1.2em;
		text-align: center;
	}

	hr {
		background: $border-primary-color;
		border-style: none;
		height: 2px;
		margin: 50px auto;
		width: 95%;

		&:first-child {
			margin-top: 0;
		}
	}

	input {
		font-size: 1.6rem;
		max-width: 100%;

		&[type="text"],
		&[type="email"] {
			border: 1px solid $border-primary-color;
			padding: .3em;

			&:focus {
				box-shadow:
					0 0 10px 0 $btn-glow-color,
					0 0 10px 0 #FFF;
				outline: none;
			}
		}
	}

	textarea {
		border: 1px solid $border-primary-color;
		font-size: 1.6rem;
		padding: .3em;
		width: 100%;

		&:focus {
			box-shadow:
				0 0 10px 0 $btn-glow-color,
				0 0 10px 0 #FFF;
			outline: none;
		}
	}

	//
	// 💊 ヘルパークラス
	//
	// 👇 以下 👇
	//
	// 🚫 削除非推奨 🚫
	//
	%grid-frame {
		align-content: flex-start;
		align-items: flex-start;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.grid-frame {
		@extend %grid-frame;
		justify-content: space-between;

		[class$="-6"] {
			flex: 0 0 48.5%;
		}

		[class$="-4"] {
			flex: 0 0 32%;
		}

		[class$="-3"] {
			flex: 0 0 24%;
		}

		@media (--xs) {
			.grid-xs-12 {
				flex: 0 0 100%;
			}

			.grid-xs-6 {
				flex: 0 0 48.5%;
			}
		}
	}

	.grid-frame-flex {
		@extend %grid-frame;
		flex-wrap: nowrap;
	}

	@for $i from 1 to 12 { // stylelint-disable-line order/order
		.grid-xs-$(i) { @media (--xs) { flex: 0 0 calc($i / 12 * 100%);margin: 10px 0 0; } }
		.grid-sm-$(i) { @media (--sm) { flex: 0 0 calc($i / 12 * 100%);margin: 31px 0 0; } }
		.grid-md-$(i) { @media (--md) { flex: 0 0 calc($i / 12 * 100%);margin: 31px 0 0; } }
		.grid-lg-$(i) { @media (--lg) { flex: 0 0 calc($i / 12 * 100%);margin: 31px 0 0; } }
		.grid-xl-$(i) { @media (--xl) { flex: 0 0 calc($i / 12 * 100%);margin: 31px 0 0; } }
	}

	.grid-xs-d5 { @media (--xs) { flex: 0 0 calc(1 / 5 * 100%); } }

	.grid-sm-d5 { @media (--sm) { flex: 0 0 calc(1 / 5 * 100%); } }

	.grid-md-d5 { @media (--md) { flex: 0 0 calc(1 / 5 * 100%); } }

	.grid-lg-d5 { @media (--lg) { flex: 0 0 calc(1 / 5 * 100%); } }

	.grid-xl-d5 { @media (--xl) { flex: 0 0 calc(1 / 5 * 100%); } }

	.grid-xs-flex { @media (--xs) { flex: 0 1 100%; } }

	.grid-sm-flex { @media (--sm) { flex: 0 1 100%; } }

	.grid-md-flex { @media (--md) { flex: 0 1 100%; } }

	.grid-lg-flex { @media (--lg) { flex: 0 1 100%; } }

	.grid-xl-flex { @media (--xl) { flex: 0 1 100%; } }

	// マージンヘルパー
	.mt-zero {
		margin-top: 0;
	}

	.mb-zero {
		margin-bottom: 0;
	}

	.mt-narrow {
		@media (--xs) { margin-top: $margin-narrow-xs; }
		@media (--sm) { margin-top: $margin-narrow-sm; }
		@media (--md) { margin-top: $margin-narrow-md; }
		@media (--lg) { margin-top: $margin-narrow-lg; }
		@media (--xl) { margin-top: $margin-narrow-xl; }
	}

	.mt-wide {
		@media (--xs) { margin-top: $margin-wide-xs; }
		@media (--sm) { margin-top: $margin-wide-sm; }
		@media (--md) { margin-top: $margin-wide-md; }
		@media (--lg) { margin-top: $margin-wide-lg; }
		@media (--xl) { margin-top: $margin-wide-xl; }
	}

	.mb-narrow {
		@media (--xs) { margin-bottom: $margin-narrow-xs; }
		@media (--sm) { margin-bottom: $margin-narrow-sm; }
		@media (--md) { margin-bottom: $margin-narrow-md; }
		@media (--lg) { margin-bottom: $margin-narrow-lg; }
		@media (--xl) { margin-bottom: $margin-narrow-xl; }
	}

	.mb-wide {
		@media (--xs) { margin-bottom: $margin-wide-xs; }
		@media (--sm) { margin-bottom: $margin-wide-sm; }
		@media (--md) { margin-bottom: $margin-wide-md; }
		@media (--lg) { margin-bottom: $margin-wide-lg; }
		@media (--xl) { margin-bottom: $margin-wide-xl; }
	}
	//
	// 💊 ヘルパークラス
	//
	// 👆 以上 👆
	//

	//
	// 🍔 BurgerEditor用
	//
	// 👇 以下 👇
	//
	// 必要ない場合は削除する 🔪
	//
	[data-bgb] {
		margin-bottom: $bgb-default-margin-bottom;
	}

	// ボタンタイプ
	.btn-group {
		margin: 40px 0;
		text-align: center;
		@media (--xs) {
			margin: 20px 0;
		}
	}

	[data-bgb=button] [data-bgt=button] {
		@media (--xs) {
			display: block;
		}
	}

	.bgt-btn,
	.btn {
		appearance: none;
		background: #E5E5E5;
		border: none;
		color: #222;
		display: inline-block;
		font-size: 20px;
		font-family: $font-family-yugo;
		line-height: 2;
		font-weight: bold;
		min-width: 320px;
		padding: 21px 2.7em 19px 2.8em;
		letter-spacing: .7px;
		margin: 10px 8px 0;
		position: relative;
		text-align: center;
		text-decoration: none;
		vertical-align: middle;
		-webkit-transition: .3s;
		transition: .3s;
		@media (--xs) {
			min-width: 0;
			width: 80%;
			font-size: 14px;
			padding: 15px 2em;
		}

		&:active,
		&:focus {
			box-shadow:
				0 0 10px 0 $btn-glow-color,
				0 0 10px 0 #FFF;
		}

		&:disabled {
			opacity: .6;
		}

		&:hover {
			background-color: #F0F0F0;
		}

		&::after {
			content: "";
			width: 11px;
			height: 20px;
			background: url("../img/common/icon-arrow-next-01.png") no-repeat;
			display: block;
			position: absolute;
			top: 50%;
			right: 30px;
			margin-top: -10px;
			@media (--xs) {
				width: 8px;
				height: 15px;
				background-size: 8px auto;
				margin-top: -7.5px;
				right: 15px;
			}
		}

		&--link {
		}

		&--em,
		&-em {
			color: #FFF;
			background: #333;
			@media (--xs) {
				border-width: 2px;
				padding: 13px 2em;
			}

			&::after {
				background: url("../img/common/icon-arrow-next-02.png") no-repeat;
				@media (--xs) {
					background-size: 8px auto;
				}
			}

			&:hover {
				background: #CE1D19;
			}
		}

		&--external,
		&-external {
			padding-right: 3em;
			@media (--xs) {
				padding-right: 2em;
			}

			&::after {
				width: 16px;
				height: 12px;
				background: url("../img/common/icon-gaibu-02.png") no-repeat;
				display: block;
				position: absolute;
				top: 50%;
				right: 41px;
				margin-top: -6px;
				@media (--xs) {
					right: 15px;
				}
			}
		}

		&--back,
		&-back {
			min-width: 180px;
			padding-left: 2.7em;
			padding-right: 1em;
			@media (--xs) {
				min-width: 0;
				width: 80%;
				padding: 15px 2em;
			}

			&::before {
				content: "";
				width: 11px;
				height: 20px;
				background: url("../img/common/icon-arrow-prev.png") no-repeat;
				display: block;
				position: absolute;
				top: 50%;
				left: 30px;
				margin-top: -10px;
				@media (--xs) {
					width: 8px;
					height: 15px;
					background-size: 8px auto;
					margin-top: -7.5px;
					left: 15px;
				}
			}

			&::after {
				display: none;
			}
		}
	}

	// 水平線
	.bgt-hr {
		margin: 0 auto;
		width: 100%;
	}

	.bgt-hr--primary {
	}

	.bgt-hr--bold {
		height: 5px;
	}

	.bgt-hr--narrow {
		height: 1px;
	}

	.bgt-hr--short {
		height: 1px;
		width: 60%;
	}

	// ダウンロードファイルタイプ
	.bgt-download-file__link {
		appearance: none;
		background: $primary-color;
		border: none;
		color: $lightest-color;
		display: inline-block;
		font-size: 1.071em;
		line-height: 2;
		min-width: 15em;
		padding: 1em 1.9em;
		position: relative;
		text-decoration: none;
		vertical-align: middle;

		&:active,
		&:focus {
			box-shadow:
				0 0 10px 0 $btn-glow-color,
				0 0 10px 0 #FFF;
		}

		&::after {
			border: 2px solid currentColor;
			border-width: 2px 2px 0 0;
			content: "";
			display: block;
			height: 10px;
			margin-top: -5px;
			position: absolute;
			right: .7em;
			top: 50%;
			transform: rotate(45deg);
			width: 10px;
		}
	}

	.bgt-link__size {
		font-size: .8em;
		font-weight: normal;
		opacity: .7;
	}

	.bgt-link__icon {
		&::after {
			vertical-align: middle;
		}
	}

	// Google Maps
	.bgt-google-maps-link {
		@media (--xs) {
			border: solid 1px #999;
			border-radius: 3px;
			color: #333;
			margin-top: 10px;
			padding: 1em;
			text-align: center;
			text-decoration: none;

			span {
				display: inline-block;
				vertical-align: middle;
			}

			&::after {
				@mixin bge-icon;
				content: "\f08e";
				font-size: 1em;
				vertical-align: middle;
			}
		}
	}

	// 画像リンクのフォーカス
	.bgt-image {
		:link { // stylelint-disable-line selector-pseudo-class-blacklist
			outline: none; // 🍔 Burger ISSUE 👎
		}
	}

	//
	// ブロックオプション用
	//

	// 背景色
	.bgb-opt--bg-gray,
	.bgb-opt--bg-blue,
	.bgb-opt--bg-pink {
		padding: $bgb-opt-padding;
	}

	// 枠線 - 適用箇所
	.bgb-opt--border-trbl {
		padding: $bgb-opt-padding;
	}

	.bgb-opt--border-td, // クラス名のタイポ // 🍔 Burger ISSUE 👎
	.bgb-opt--border-tb {
		border-left-width: 0 !important; // stylelint-disable-line declaration-no-important
		border-right-width: 0 !important; // stylelint-disable-line declaration-no-important
		padding-bottom: $bgb-opt-padding;
		padding-top: $bgb-opt-padding;
	}

	.bgb-opt--border-lr {
		padding-left: $bgb-opt-padding;
		padding-right: $bgb-opt-padding;
	}

	.bgb-opt--border-trl {
		padding-left: $bgb-opt-padding;
		padding-right: $bgb-opt-padding;
		padding-top: $bgb-opt-padding;
	}

	.bgb-opt--border-rbl {
		padding-bottom: $bgb-opt-padding;
		padding-left: $bgb-opt-padding;
		padding-right: $bgb-opt-padding;
	}

	// 余白
	.bgb-opt--mb-large {
		margin-bottom: calc($bgb-default-margin-bottom * 2.8) !important; // stylelint-disable-line declaration-no-important
	}

	.bgb-opt--mb-small {
		margin-bottom: calc($bgb-default-margin-bottom / 2) !important; // stylelint-disable-line declaration-no-important
	}

	.bgb-opt--mb-none {
		margin-bottom: 0 !important; // stylelint-disable-line declaration-no-important
	}

	// Wysiwygタイプと画像の余白
	[data-bgt="ckeditor"] {
		&:nth-child(2) {
			margin-top: 1em;
		}
	}
	//
	// 👆 以上 👆
	//
	// 🍔 BurgerEditor用
	//

	//
	// 👇 以下 👇
	//
	// 🐲 baserCMS用
	//
	// 必要ない場合は削除する 🔪
	//
	.bc-heading {
		h2 {
		}
	}

	.bc-time {
		margin-bottom: 5em;
		text-align: right;

		time {
		}
	}

	.bc-cat {
		margin-bottom: 1em;
		text-align: right;

		> div {
			background: $primary-color;
			color: $lightest-color;
			display: inline-block;
			font-size: .8em;
			padding: .2em .4em;
		}
	}

	.bc-area-cta {
		margin-top: 4em;
		text-align: center;

		a {
			background: $primary-color;

			span {
				&::before {
					@mixin bge-icon;
					color: #919191;
					content: "\f0e0";
					margin-right: .5em;
				}
			}
		}
	}

	.bc-area-ctrl {
		margin-top: 6em;
		@media (--xs) {
			text-align: center;
		}
	}

	.bc-form-table {

	}

	.bc-form-agreement {
		margin-top: 4em;
		text-align: center;
	}

	.bc-form-submit {
		margin-top: 3em;
		text-align: center;
	}

	&-form-block {
		$input-font-size: 1.6rem;
		$input-line-height: 30px;
		$input-border-radius: 0;
		$input-bg-color: #F4F4F4;
		margin-top: 54px;
		@media (--xs) {
			margin-top: 0;
		}

		.btn-em {
			background: #CE1D19;

			&:hover {
				background: #333;
			}
		}

		th {
			width: 245px;
			vertical-align: top;
		}

		.text-error {
			color: #CC001B;
		}

		.text-required {
			display: inline-block;
			background: #CE201A;
			color: #FFF;
			text-align: center;
			width: 58px;
			font-size: 14px;
			vertical-align: middle;
			margin-left: 7px;
			line-height: 1.5;
			padding-top: 1px;
			@media (--xs) {
				width: 50px;
				font-size: 13px;
			}
		}

		.input-text-s {
			width: 159px;
			@media (--xs) {
				width: 100%;
			}
		}

		.input-text-m {
			width: 51%;
			margin-right: 21px;
			@media (--xs) {
				width: 100%;
				margin-right: 0;

				&.input-zip {
					width: 51%;
				}
			}
		}

		.input-text-l {
			width: 500px;
			@media (--xs) {
				width: 100%;
			}
		}

		select {
			font-size: $input-font-size;
		}

		input[type="text"],
		input[type="date"],
		input[type="number"],
		input[type="email"],
		input[type="tel"] {
			background-color: $input-bg-color;
			background-image: none;
			border: none;
			box-sizing: border-box;
			font-size: $input-font-size;
			line-height: $input-line-height;
			height: $input-line-height;
			-webkit-appearance: none;
			border-radius: $input-border-radius;
			padding: 2px 10px;
			margin-top: -2px;
			vertical-align: middle;
			@media (--xs) {
				margin-bottom: 5px;
			}
		}

		textarea {
			background-color: $input-bg-color;
			background-image: none;
			border: none;
			box-sizing: border-box;
			font-size: $input-font-size;
			line-height: $input-line-height;
			height: 120px;
			-webkit-appearance: none;
			border-radius: $input-border-radius;
			padding: 2px 10px;
			margin: -2px 0 -1px;
			display: block;
		}

		:placeholder-shown {
			color: #999;
		}

		::-webkit-input-placeholder {
			color: #999;
		}

		:-moz-placeholder {
			color: #999;
			opacity: 1;
		}

		::-moz-placeholder {
			color: #999;
			opacity: 1;
		}

		:-ms-input-placeholder {
			color: #999;
		}

		input[type="radio"],
		input[type="checkbox"] {
			vertical-align: middle;
			margin-right: 5px;
			@media (--xs) {
				-moz-transform-origin: right center;
				-moz-transform: scale(1.5);
				-webkit-transform-origin: right center;
				-webkit-transform: scale(1.5);
			}

			& + label {
				vertical-align: middle;
				margin-right: 13px;
				@media (--xs) {
					margin-right: 0;
				}
			}
		}

		input[type="radio"] {
			@media (--xs) {
				margin-right: 5px;
				vertical-align: 0;
			}

			& + label {
				font-size: $input-font-size;

				@media (--xs) {
					display: inline-block;
					margin-right: 10px;
					margin-bottom: 10px;
					width: calc(100% - 43px);
					vertical-align: 0;

					&:last-child {
						margin-bottom: 5px;
					}
				}
			}
		}

		.-bc-form-select-wrapper {
			position: relative;
			display: block;
			margin-top: -2px;
		}

		.-bc-form-select {
			display: block; /* 必須 */
			position: absolute; /* 必須 */
			top: 0; /* 必須 */
			left: 0; /* 必須 */
			z-index: -1; /* 必須 */
			width: 1px; /* 必須 */
			height: 1px; /* 必須 */
			appearance: none; /* 必須 */
			-webkit-appearance: none;
			-moz-appearance: none;
			-ms-appearance: none;
			opacity: 0; /* 必須 */
			filter: alpha(opacity=0); /* 必須 */
			-ms-filter: "alpha(opacity=0)"; /* 必須 */
		}

		.-bc-use-default-option-list {
			.-bc-form-select {
				width: 100%; /* 必須 */
				height: 100%; /* 必須 */
				z-index: 10000; /* 推奨 他のz-indexにあわせて調整 */
			}
		}

		.-bc-pseudo-select {
			box-sizing: border-box;
			border-radius: $input-border-radius;
			display: block;
			font-size: $input-font-size;
			line-height: $input-line-height;
			height: $input-line-height;
			padding: 0;
			position: relative;
			text-decoration: none;
			vertical-align: middle;
			-webkit-appearance: none;

			&__selected-display {
				box-sizing: border-box;
				background-color: $input-bg-color;
				border: none;
				border-radius: $input-border-radius;
				display: block;
				overflow: hidden;
				padding: 0 10px;
				position: relative;

				&::after {
					content: "";
					position: absolute;
					display: block;
					width: 38px;
					height: 34px;
					border-left: none;
					top: 0;
					right: 0;
				}
			}

			&:hover {
				opacity: 1;
				text-decoration: none;
			}

			&__option-list {
				display: none;
				position: absolute;
				top: 1px;
				left: 0;
				width: 100%;
				height: 300px;
				overflow: auto;
				background-color: $input-bg-color;
				border: none;
				border-radius: $input-border-radius;
				border-top: none;
				z-index: 1000;
				margin: 0;
				padding: 0;

				li {
					font-size: 14px;
					line-height: 1.5;
					background-color: $input-bg-color;
					border-top: none;
					margin: 0;
					padding: 7px 0 7px 12px;

					&::before {
						display: none;
					}

					&:first-child {
						border-top: none;
					}

					&:hover {
						background-color: #E3E3E3;
					}
				}
			}
		}

		.-bc-pseudo-select--focus {
			.-bc-pseudo-select__option-list {
				display: block;
				overflow: auto;
			}
		}

		.-bc-form-element__label--disabled,
		.-bc-form-element--disabled {
			.-bc-pseudo-select {
				pointer-events: none; /* 必須 for タッチデバイス */
			}
		}

		.-bc-form-radio__label {
			background: url("../img/common/bg-radio-off.png") no-repeat 5px center;
			display: inline-block;
			margin: 5px 0;
			padding: 5px 5px 5px 35px;
			position: relative;
			@media (--xs) {
				display: block;
			}
		}

		.-bc-form-radio__label.-bc-form-element__label--checked {
			background: url("../img/common/bg-radio-on.png") no-repeat 5px center;
		}

		.-bc-form-checkbox__label {
			background: url("../img/common/bg-check-off.png") no-repeat 5px center;
			display: inline-block;
			margin: 5px 0;
			padding: 5px 5px 5px 35px;
			position: relative;
			@media (--xs) {
				display: block;
			}
		}

		.-bc-form-checkbox__label.-bc-form-element__label--checked {
			background: url("../img/common/bg-check-on.png") no-repeat 5px center;
		}

		.-bc-form-radio-wrapper,
		.-bc-form-checkbox-wrapper {
			opacity: 0;
			width: 0;
			height: 0;
			overflow: hidden;
			position: absolute;
		}

		.-bc-form-radio__label + span + .-bc-form-radio__label,
		.-bc-form-checkbox__label + span + .-bc-form-checkbox__label {
			margin-left: 35px;
			@media (--xs) {
				margin-left: 0;
			}
		}
	}

	.form-privacy,
	.form-thanks {
		margin: 60px 0 51px;
		width: 1000px;
		text-align: center;
		background: url("../img/common/bg-pagination.png");
		padding: 20px 0 26px;
		@media (--xs) {
			margin: 30px 0;
			width: auto;
			background-size: 2px auto;
			padding: 15px;
		}

		&-title {
			font-size: 20px;
			font-weight: bold;
			color: #CC001B;
			font-family: $font-family-mincho;
			letter-spacing: .2px;
			margin-bottom: 0;
			@media (--xs) {
				font-size: 15px;
			}
		}

		&-txt {
			margin: 3px 0 0;
			font-size: 16px;
			line-height: 1.5;
			@media (--xs) {
				font-size: 12px;
			}
		}

		&-check {
			margin: 3px 0 0;
			font-size: 16px;
			font-weight: bold;
			@media (--xs) {
				font-size: 12px;
				margin-top: 5px;
			}

			input[type="checkbox"] + label {
				margin-left: 6px;
				margin-right: 0;
				letter-spacing: .2px;
				@media (--xs) {
					margin-left: 1px;
				}
			}
		}
	}

	.form-thanks {
		margin: 53px 0 40px;
		padding: 20px 0 23px;
		@media (--xs) {
			margin: 30px 0;
			padding: 15px;
		}

		&-title {
			letter-spacing: -1px;
			font-size: 22px;
			@media (--xs) {
				font-size: 15px;
			}
		}

		&-txt {
			margin: 1px 0 0;
		}

		& + .btn-group {
			.btn-back {
				padding: 21px 2.2em 19px 3.3em;
				@media (--xs) {
					padding: 15px 2em;
				}
			}
		}
	}
	// バーガーブロック余白調整
	// 4カラム
	[data-bgb=image4] {
		.bgt-grid--first {
			padding-left: 13.33333px;
		}

		.bgt-grid {
			padding-right: 0;
			padding-left: 13.33333px;
		}

		.bgt-grid--last {
			padding-right: 0;
			padding-left: 13.33333px;
		}
	}
	// stylelint-enable selector-nested-pattern
}

.c-content-main {
	.pc-only {
		display: block;

		@media (--xs) {
			display: none;
		}
	}

	.sp-only {
		display: none;

		@media (--xs) {
			display: block;
		}
	}

	.bgb-menu {
		display: none;
	}
}

// stylelint-enable selector-class-pattern
