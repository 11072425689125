/* stylelint-disable selector-no-universal */
// this is universal selector rules
* {
	&,
	&::before,
	&::after {
		box-sizing: border-box;
	}

	&::selection {
		background-color: $selection-background-color;
	}
}
