.c-pagination {
	width: 1000px;
	background: url("../img/common/bg-pagination.png");
	display: flex;
	font-size: 20px;
	font-weight: bold;
	justify-content: center;
	line-height: 5.4rem;
	margin: 80px 0;
	padding: 16px 0 17px;
	overflow: hidden;
	text-align: center;
	font-family: $font-family-yugo;
	color: $primary-color;
	@media (--xs) {
		width: auto;
		margin: 30px 0;
		font-size: 13px;
		padding: 15px;
		background-size: 2px auto;
	}

	@media (--xs) {
		justify-content: space-between;
	}

	a {
		background: none;
		color: #CE1D19;
		-webkit-transition: .3s;
		transition: .3s;

		&:hover {
			opacity: .5;
		}
	}

	&__prev,
	&__next {
		color: #000;
		letter-spacing: .8px;

		a {
			display: block;
			overflow: hidden;
			padding: 3px 0 0;
			position: relative;
			text-decoration: none;
			color: #000;

			&[data-disabled="true"] {
				opacity: .3;
				pointer-events: none;
			}
		}
	}

	&__prev {
		order: 1;
		margin-right: 2.9em;
		@media (--xs) {
			margin-right: 0;
		}

		a {
			@media (--xs) {
				overflow: hidden;
				width: 34px;
				height: 34px;
				padding: 0;
			}

			&::before {
				content: "";
				width: 11px;
				height: 20px;
				background: url(../img/common/icon-arrow-prev.png) no-repeat;
				display: inline-block;
				vertical-align: -3px;
				margin-right: 13px;
				@media (--xs) {
					background-position: 12px center;
					background-size: 8px auto;
					width: 34px;
					height: 34px;
					border-radius: 17px;
					margin: 0;
					display: block;
				}
			}
		}
	}

	&__next {
		order: 3;
		margin-left: 2.9em;
		@media (--xs) {
			margin-left: 0;
		}

		a {
			@media (--xs) {
				overflow: hidden;
				width: 34px;
				height: 34px;
				padding: 0;
				text-indent: -200px;
			}

			&::after {
				content: "";
				width: 11px;
				height: 20px;
				background: url(../img/common/icon-arrow-next-01.png) no-repeat;
				display: inline-block;
				vertical-align: -3px;
				margin-left: 13px;
				@media (--xs) {
					background-position: 15px center;
					background-size: 8px auto;
					width: 34px;
					height: 34px;
					border-radius: 17px;
					margin: 0;
					display: block;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
	}

	&__numbers,
	&__list {
		display: flex;
		list-style: none;
		margin: 0 2em;
		order: 2;
		padding: 0;
		@media (--xs) {
			line-height: 2.5;
			margin: 0 .5em;
			font-size: 15px;
			flex-wrap: wrap;
		}
	}

	&__number {
		display: block;
		list-style: none;
		margin: 0 .25em;
		color: #CE1D19;
		@media (--xs) {
			margin: 0 .3em .3em;
			min-width: 34px;
		}

		&::before {
			display: none;
		}

		// 🐲 baserCMSの仕様上 マルチクラスでしかカレントを定義できない
		&--current { // stylelint-disable-line selector-class-pattern
			text-decoration: none;
			color: #000;
			margin: 0 .55em;
			@media (--xs) {
				margin: 0 .3em .3em;
				min-width: 34px;
			}

			a:not([href]) {
			}
		}

		a {
			display: block;
			padding: 0 .3em;
			text-align: center;

			&::after {
				display: none;
			}
		}
	}
}
