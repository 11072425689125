.c-card {
	border: 1px solid $border-primary-color;
	height: 100%;
	outline: 2px solid transparent;
	overflow: hidden;
	position: relative;
	transition: outline 300ms;

	&:hover {
		outline: 2px solid $border-primary-color;
	}

	&[data-is-new="true"] {
		&::before {
			background: $primary-color;
			color: $lightest-color;
			content: "new";
			display: block;
			font-size: 12px;
			height: 74px;
			left: -38px;
			padding: 50px 0 0;
			position: absolute;
			text-align: center;
			top: -38px;
			transform: rotate(-45deg);
			width: 74px;
			z-index: 1;
		}
	}

	> a {
		color: currentColor;
		height: 100%;
		text-decoration: none;
	}

	&__img {
		border-bottom: 1px solid $border-primary-color;
		flex: 0 1 auto;
		height: 0;
		overflow: hidden;
		padding-top: calc(9 / 16 * 100%);
		position: relative;
		width: 100%;

		img {
			display: block;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;

			@supports (object-fit: cover) {
				height: 100%;
				object-fit: cover;
				object-position: center top;
			}
		}
	}

	&__header {
		flex: 0 1 auto;
		padding: 16px 22px 0;
	}

	&__body {
		flex: 0 1 100%;
		font-size: 14px;
		padding: 10px 22px 38px;
	}

	&__title {
		font-size: 20px;
		margin: 0;
		padding: 0;
	}

	// ブログのみ
	&__meta {

	}

	// ブログのみ
	&__time {
		display: none;
	}

	// ブログのみ
	&__category {

	}

	// ブログのみ
	&__tags {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			text-align: right;
		}
	}

	// ブログのみ
	&__tag-item {
		display: inline-block;
		font-size: 10px;
		list-style: none;
		margin: 0;
		padding: 0;

		span {
			background: $primary-color;
			color: $lightest-color;
			display: block;
			padding: 3px 15px;
		}
	}

	// ブログのみ
	&__author {
		display: none;
	}

	&__description {
		margin: 0;
		padding: 0;

		p {
			margin: 0;
			padding: 0;
		}
	}
}
