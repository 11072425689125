@font-face {
	font-family: YuGothicM;
	src: local("Yu Gothic Medium");
}

@font-face {
	font-family: icons;
	font-style: normal;
	font-weight: normal;
	src: url("../font/icons.eot");
	src:
		url("../font/icons.eot?#iefix") format("embedded-opentype"),
		url("../font/icons.woff") format("woff"),
		url("../font/icons.ttf") format("truetype");
}

$font-family-primary: "メイリオ", "MS PGothic", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "ＭＳ Ｐゴシック", sans-serif;
$font-family-mincho: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
$font-family-yugo: YuGothic, "游ゴシック", Meiryo, "メイリオ", "MS PGothic", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "ＭＳ Ｐゴシック", sans-serif;
$font-family-eng: 'Times New Roman';
$font-family-heading: "Helvetica";
$font-family-icons: icons;

@define-mixin icon {
	display: inline-block;
	font-family: icons;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	speak: none;
	text-align: left;
	text-indent: 0;
	text-rendering: auto;
	text-transform: none;
	vertical-align: middle;
	white-space: nowrap;
}

@define-mixin bge-icon {
	@mixin icon;
	font-family: FontAwesome;
}

$glyph-space: "\e020";
$glyph-email: "\e100";
$glyph-movie: "\e110";
$glyph-home: "\e130";
$glyph-phone: "\e140";
$glyph-facebook: "\e200";
