.c-content-index {

	&__body {
		width: 1000px;
		margin: 58px auto 0;
		@media (--xs) {
			width: auto;
			padding: 0 15px;
			margin: 0 auto;
		}
	}

	&__card-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	&__card-item {
		// 💉 最後の-1pxはIE11のカラム落ち対策
		flex-basis: calc(1 / $card-cols * (100% - $card-cols-side-margin * ($card-cols - 1)) - 1px);
		flex-grow: 0;
		flex-shrink: 0;
		margin-bottom: $card-cols-vertical-margin;
		margin-right: $card-cols-side-margin;

		@media (--xs) {
			// 💉 最後の-1pxはIE11のカラム落ち対策
			flex-basis: calc(1 / $card-cols-xs * (100% - $card-cols-side-xs-margin * ($card-cols-xs - 1)) - 1px);
			margin-bottom: $card-cols-vertical-xs-margin;
			margin-right: $card-cols-side-xs-margin;
		}

		@media (--sm) {
			// 💉 最後の-1pxはIE11のカラム落ち対策
			flex-basis: calc(1 / $card-cols-sm * (100% - $card-cols-side-sm-margin * ($card-cols-sm - 1)) - 1px);
			margin-bottom: $card-cols-vertical-sm-margin;
			margin-right: $card-cols-side-sm-margin;
		}

		&:nth-child($(card-cols)n) {
			margin-right: 0;
		}
	}

	&__news-list {
		list-style: none;
		padding: 0;
		margin: -5px 0 100px;
		@media (--xs) {
			margin: 0 0 40px;
		}

		li {
			border-bottom: solid 1px #E4E4E4;
			margin-bottom: 20px;
			@media (--xs) {
				margin-bottom: 10px;
			}

			> span {
				display: block;
				padding: 0 40px 19px 19px;
				overflow: hidden;
				text-decoration: none;
				color: #000;
				position: relative;
				@media (--xs) {
					padding: 0 20px 10px 10px;
				}
			}

			a {
				display: block;
				padding: 0 40px 19px 19px;
				overflow: hidden;
				text-decoration: none;
				color: #000;
				position: relative;
				-webkit-transition: .3s;
				transition: .3s;
				@media (--xs) {
					padding: 0 20px 10px 10px;
				}

				&:hover {
					opacity: .5;
				}

				&::after {
					content: "";
					width: 9px;
					height: 20px;
					display: block;
					background: url("../img/news/icon-news-arrow.png") no-repeat;
					position: absolute;
					top: 50%;
					right: 20px;
					margin-top: -20px;
					@media (--xs) {
						width: 6px;
						height: 13px;
						background-size: 6px auto;
						right: 5px;
						margin-top: -10px;
					}
				}
			}
		}

		p {
			margin: 0;
			overflow: hidden;
			letter-spacing: .2px;
			line-height: 1.55;
			@media (--xs) {
				font-size: 12px;
				letter-spacing: 0;
			}
		}

		&-ph {
			width: 218px;
			float: left;
			text-align: center;
			margin-right: 20px;
			@media (--xs) {
				width: 120px;
				margin-right: 7px;
			}

			img {
				max-width: 100%;
				vertical-align: bottom;
			}

			& + p {
				font-family: $font-family-eng;
				color: #D11931;
				padding: 16px 0 9px;
				letter-spacing: 0;
				@media (--xs) {
					padding: 5px 0 1px;
					font-size: 10px;
					letter-spacing: .7px;
				}
			}
		}
	}
}
