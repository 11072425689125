.c-hero {
	// ☝ slick.js で自動生成されるDOMを参考にする
	// 📝 http://kenwheeler.github.io/slick/
	margin-top: -69px;
	position: relative;
	@media (--xs) {
		margin: 0;
	}

	&__slideshow {
		overflow: hidden;
	}

	&__item {
		width: 100%;
		float: left;

		img {
			width: 100%;
			display: block;
		}
	}

	[role="tablist"] {
		position: absolute;
		bottom: 21px;
		left: 0;
		right: 0;
		z-index: 1100;
		margin: 0;
		padding: 0;
		list-style: none;
		text-align: center;
		@media (--xs) {
			bottom: 22.5px;
		}

		> li {
			display: inline-block;
			cursor: pointer;
			margin: 0 4px;
			width: 28px;
			height: 3px;
			background: #BA1928;
			vertical-align: bottom;
			@media (--xs) {
				margin: 0 2px;
				width: 14px;
				height: 1.5px;
			}

			button {
				visibility: hidden;
				width: 28px;
				height: 3px;
			}
		}

		.slick-active {
			background: #FFF;
		}
	}
}
