.c-list-category {
	font-size: 1.2rem;

	@media (--xs) {
		display: none;
	}

	ul {
		display: flex;
		justify-content: center;
		list-style: none;
		margin: 0 0 26px;
		padding: 0;
	}

	li {
		list-style: none;
		margin: 0 .3em;
		padding: 0;
	}

	a {
		background: $primary-color;
		color: $lightest-color;
		display: block;
		padding: 1em 1.9em;
		text-decoration: none;
	}
}
