.c-page-home {
  min-width: 1075px;
  @media (--xs) {
    min-width: 0;
  }

  .c-header {
    position: relative;
    z-index: 1100;

  	&__site-name {
      position: absolute;
      left: 0;
      right: 50%;
      top: 0;
      background: #000;
      margin-top: 42.857%;
      width: auto;
      margin-right: 340px;
      padding: 15px 48px 15px 0;
  		@media (--xs) {
        position: static;
  			width: 215px;
  			padding: 11px 37.5px 11px 16px;
  			background: #000 url("../img/common/bg-head-logo-sp.png") no-repeat right top;
  			background-size: 20.5px auto;
    		margin: 0;
  		}

      a {
        width: 264px;
        margin: 0 0 0 auto;
    		@media (--xs) {
    			width: auto;
      		margin: 0;
    		}
      }
  	}

  	&__language {
  		min-width: 178px;
  	}
  }

  .c-nav-global {
    width: 870px;
    background: #FFF url("../img/home/bg-head-logo-pc.png") no-repeat;
    position: absolute;
    left: 50%;
    right: 0;
    top: 0;
    margin-top: 42.857%;
    margin-left: -340px;
  	padding: 23px 0 15px 128px;
  	@media (--xs) {
      width: auto;
      top: auto;
  		left: 0;
  		right: 0;
  		padding: 0;
      margin: 0;
  	}

  	&__item,
  	&__item-pc {
  		> a {
  			color: #222;
      	@media (--xs) {
					color: $lightest-color;
        }

  			&[target="_blank"] {
  				padding-right: 29px;
  				background: url("../img/common/icon-gaibu-01.png") no-repeat right 3px;
        	@media (--xs) {
  					background: url("../img/common/icon-gnav-01.png") no-repeat left center;
  					background-size: 9px auto;
  					padding: 13px 20px 11px 21px;
          }
  			}
      }

  		& + li {
  			margin-left: 70px;
  			padding-left: 70px;
      	@media (--xs) {
  				background: none;
  				margin-left: 0;
  				padding-left: 0;
  				border-top: solid 1px #666;
        }
  		}
    }
  }

  .c-page-home {
    min-width: 0;
    background: url("../img/home/bg-01-pc.png") no-repeat center top;
    background-size: cover;
    margin-top: 79px;
    border-top: solid 1px #EEE;
    @media (--xs) {
      background: url("../img/home/bg-01-sp.png") no-repeat;
      background-size: 100% auto;
      margin-top: 0;
      border-top: none;
    }
  }

  &__service {
    width: 1000px;
    margin: 0 auto;
    padding-top: 99px;
    @media (--xs) {
      width: auto;
      padding-top: 44px;
    }

    h2 {
      font-size: 40px;
      font-family: $font-family-eng;
      text-align: center;
      margin: 0 0 35px;
      font-weight: normal;
      color: #000;
      line-height: 1.55;
      letter-spacing: 1.4px;
      @media (--xs) {
        font-size: 26px;
        letter-spacing: 1.1px;
        margin: 0 0 17px;
      }

      &::before {
        content: "";
        width: 32px;
        height: 32px;
        background: url("../img/home/icon-service.png") no-repeat;
        background-size: 100% auto;
        display: block;
        margin: 0 auto 2px;
        @media (--xs) {
          width: 21px;
          height: 21px;
          margin: 0 auto;
        }
      }

      span {
        display: block;
        color: #CE201A;
        font-size: 13px;
        letter-spacing: 0;
        @media (--xs) {
          font-size: 10px;
          margin-top: -4px;
        }

        &::before,
        &::after {
          content: "";
          width: 34px;
          height: 1px;
          background: #CE201A;
          vertical-align: 5px;
          margin: 0 9px;
          display: inline-block;
          @media (--xs) {
            width: 29px;
            margin: 0 6px;
            vertical-align: 3px;
          }
        }
      }
    }

    &-list {
      padding: 0;
      overflow: hidden;
      margin-left: -20px;
      list-style: none;
      @media (--xs) {
        margin: 0 6.25%;
      }

      li {
        width: 320px;
        float: left;
        margin-left: 20px;
        @media (--xs) {
          width: auto;
          float: none;
          margin: 0 0 25.5px;
        }

        a {
          text-decoration: none;
          color: #000;
          font-size: 13px;
          display: block;
    			-webkit-transition: .3s;
    			transition: .3s;
          @media (--xs) {
            font-size: 12px;
            letter-spacing: -.4px;
          }

    			&:hover {
    				opacity: .5;
    			}

          img {
            max-width: 100%;
          }
        }

        h3 {
          text-align: center;
          font-weight: 600;
    			font-family: $font-family-mincho;
          line-height: 1.2;
          font-size: 22px;
          min-height: 75px;
          margin: 0 auto 13px;
          padding: 10px 0 12px;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
          align-items: center;
          -webkit-justify-content: center;
          justify-content: center;
          border-bottom: solid 1px #CE201A;
          position: relative;
          @media (--xs) {
            font-size: 15px;
            padding: 3px 0 6px;
            min-height: 0;
            margin: 0 auto 8px;

            br {
              display: none;
            }
          }

          &::after {
            content: "";
            width: 7px;
            height: 13px;
            display: block;
            background: url("../img/home/icon-arrow-01-pc.png") repeat-y;
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -7px;
            @media (--xs) {
              width: 6px;
              height: 10px;
              background: url("../img/home/icon-arrow-01-sp.png") repeat-y;
              background-size: 100% auto;
              margin-top: -8px;
              right: 0;
            }
          }
        }

        p {
          margin: 0 10px;
          font-size: 14px;
          line-height: 1.6;
          @media (--xs) {
            margin: 0;
            line-height: 1.45;
          }
        }
      }
    }

    &-item-ph {
      @media (--sm) {
        overflow: hidden;
      }

      img {
        @media (--sm) {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &__news {
    width: 1001px;
    margin: 0 auto;
    padding-top: 77px;
    @media (--xs) {
      width: auto;
      padding-top: 19.5px;
    }

    h2 {
      font-size: 40px;
      font-family: $font-family-eng;
      text-align: center;
      margin: 0 0 2px;
      font-weight: normal;
      color: #000;
      line-height: 1.55;
      letter-spacing: 1.4px;
      @media (--xs) {
        font-size: 26px;
        letter-spacing: 1px;
        margin: 0 0 3px;
      }

      &::before {
        content: "";
        width: 25px;
        height: 32px;
        background: url("../img/home/icon-news.png") no-repeat;
        background-size: 100% auto;
        display: block;
        margin: 0 auto 2px;
        @media (--xs) {
          width: 16px;
          height: 21px;
          margin: 0 auto 1px;
        }
      }
    }

    &-more {
      text-align: center;
      margin: 0 0 40px;
      @media (--xs) {
        margin: 0 0 20.5px;
      }

      a {
        width: 210px;
        display: inline-block;
        background: #000;
        color: #FFF;
        font-family: $font-family-mincho;
        font-size: 13px;
        text-decoration: none;
        padding: 6px 0;
        letter-spacing: -1.4px;
        -webkit-transition: .3s;
        transition: .3s;
        @media (--xs) {
          width: 200px;
          padding: 9px 2px 11px 0;
          font-size: 12px;
        }

        &:hover {
          opacity: .5;
        }

        &::after {
          content: "›";
          font-size: 17px;
          margin-left: 6px;
          vertical-align: -1px;
          @media (--xs) {
            font-size: 15px;
            margin-left: 11px;
            vertical-align: 0;
          }
        }
      }
    }

    &-list {
      padding: 0 0 6px;
      overflow: hidden;
      margin-left: -14px;
      margin-right: -1px;
      list-style: none;
      @media (--xs) {
        margin: 0 6.25%;
        padding: 0;
      }
    }

    &-item {
      width: 240px;
      float: left;
      margin-left: 14px;
      @media (--xs) {
        width: 48.214%;
        margin-left: 0;
        margin-bottom: 20px;

        &:nth-child(2n) {
          float: right;
        }
      }

      img {
        max-width: 100%;
      }

      a,
      span {
        text-decoration: none;
        color: #000;
        font-size: 13px;
        background: #FFF;
        display: block;
        height: 100%;
        padding-bottom: 16px;
        -webkit-box-shadow: 0px 4px 5px -3px rgba(10, 2, 4, .3);
        -moz-box-shadow: 0px 4px 5px -3px rgba(10, 2, 4, .3);
        box-shadow: 0px 4px 5px -3px rgba(10, 2, 4, .3);
        -webkit-transition: .3s;
        transition: .3s;
        @media (--xs) {
          padding-bottom: 11px;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          border-bottom: solid 1px #D2D2D2;
        }
      }

      a {
        &:hover {
          opacity: .5;
        }
      }

      &-date {
        color: #CC091C;
        font-size: 15px;
        font-family: $font-family-eng;
        padding: 1px 14px;
        letter-spacing: -.9px;
        margin: 0;
        @media (--xs) {
          margin-top: -3px;
          font-size: 10px;
          padding: 0 8px;
          letter-spacing: -.3px;
        }

        & + p {
          text-decoration: underline;
          padding: 1px 14px;
          margin: 2px 0 0;
          letter-spacing: 1px;
          line-height: 1.6;
          @media (--xs) {
            padding: 0 8px;
            font-size: 12px;
            margin: 1px 0 0;
            letter-spacing: -.2px;
            line-height: 1.45;
          }
        }
      }
    }

    span {
      p {
        text-decoration: none;
      }
    }

    &-item-ph {
      @media (--sm) {
        overflow: hidden;
      }

      img {
        @media (--sm) {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &__performance {
    background: url("../img/home/bg-performance-pc.jpg") no-repeat;
    background-size: cover;
    margin-top: 130px;
    padding: 84px 0;
    @media (--xs) {
      background: url("../img/home/bg-performance-sp.jpg") no-repeat;
      background-size: 100% auto;
      margin-top: 29px;
      padding: 58px 0 57px;
    }

    &-block {
      width: 660px;
      margin: 0 auto;
      border: solid 4px #FFF;
      overflow: hidden;
      padding: 26px 0;
      @media (--xs) {
        width: auto;
        margin: 0 6.25%;
        border: solid 2px #FFF;
        padding: 10px 0 15px;
      }

      h2 {
        width: 381px;
        float: left;
        color: #FFF;
        font-family: $font-family-mincho;
        font-weight: normal;
        font-size: 20px;
        margin: 9px 0 0 35px;
        @media (--xs) {
          width: auto;
          float: none;
          font-size: 13px;
          text-align: center;
          margin: 0 0 4px;
        }
      }
    }

    &-button {
      width: 200px;
      float: left;
      text-align: center;
      font-family: $font-family-mincho;
      margin: 0;
      @media (--xs) {
        float: none;
        margin: 0 auto;
      }

      a {
        text-decoration: none;
        color: #FFF;
        font-size: 18px;
        display: block;
        padding: 11px 0 9px 2px;
        background: url("../img/common/bg-repeat-01.png");
        position: relative;
        -webkit-transition: .3s;
        transition: .3s;
        @media (--xs) {
          background-size: 2px;
          font-size: 13px;
          padding: 10px 0 8px 2px;
        }

        &:hover {
          opacity: .5;
        }

        &::after {
          content: "";
          width: 7px;
          height: 12px;
          display: block;
          background: url("../img/home/icon-performance-arrow-pc.png") no-repeat;
          position: absolute;
          top: 50%;
          right: 10px;
          margin-top: -6px;
          @media (--xs) {
            width: 6px;
            height: 10px;
            background: url("../img/home/icon-performance-arrow-sp.png") no-repeat;
            background-size: 100% auto;
            margin-top: -5px;
          }
        }
      }
    }
  }

  &__banner {
    width: 710px;
    margin: 70px auto 0;

    @media (--xs) {
      width: calc(100% - 40px);
      margin-top: 40px;
    }

    a {
      display: block;
    }

    img {
      @media (--xs) {
        width: 100%;
      }
    }
  }
}
