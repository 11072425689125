.c-page-sub {
	&__content {
		margin: 0 auto;
		padding-bottom: 54px;
		min-width: 1000px;
		@media (--xs) {
			margin: 0 auto;
			padding-bottom: 20px;
			min-width: 0;
		}
	}

	&__content-title {
	}

	&__breadcrumb {
	}

	&__content-detail {
		width: 1000px;
		margin: 0 auto;
		@media (--xs) {
			width: auto;
		}
	}
}
