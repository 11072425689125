.c-header {

	&__body {
		width: 1000px;
		margin: 0 auto;
		padding-bottom: 21px;
		overflow: hidden;
		@media (--xs) {
			width: auto;
			padding-bottom: 0;
		}
	}

	&__site-name {
		padding: 20px 0 0;
		margin: 0;
		float: left;
		@media (--xs) {
			width: 215px;
			padding: 11px 37.5px 11px 16px;
			background: #000 url("../img/common/bg-head-logo-sp.png") no-repeat right top;
			background-size: 20.5px auto;
		}

		> a {
			display: block;
			text-decoration: none;
			-webkit-transition: .3s;
			transition: .3s;

			&:hover {
				opacity: .5;
			}

			img {
				display: block;
				@media (--xs) {
					width: 100%;
				}
			}
		}
	}

	&__contact {
		min-width: 170px;
		float: right;
		@media (--xs) {
			display: none;
		}

		> a {
			display: block;
			font-family: $font-family-mincho;
			color: #FFF;
			background: #CE201A;
			text-decoration: none;
			text-align: center;
			padding: 14px 20px 0 55px;
			font-size: 14px;
			height: 48px;
			position: relative;
			-webkit-transition: .3s;
			transition: .3s;

			&:hover {
				opacity: .5;
			}

			&::before {
				content: "";
				width: 24px;
				height: 16px;
				background: url("../img/common/icon-contact-01-pc.png") no-repeat;
				display: block;
				position: absolute;
				top: 16px;
				left: 28px;
			}
		}
	}

	&__language {
		margin-left: 1px;
		min-width: 179px;
		height: 48px;
		padding: 10px 10px 0;
		background: #EEE;
		text-align: center;
		float: right;
		@media (--xs) {
			display: none;
		}

		> a {
			text-decoration: none;
		}
	}

	&__spmenu {
		display: none;
		@media (--xs) {
			display: block;
			width: 35px;
			height: 35px;
			border: solid 1px #CC001B;
			position: absolute;
			top: 10px;
			right: 10px;
			transition: all 300ms;
		}

		&::before,
		&::after {
			@media (--xs) {
				background: #CC001B;
				content: "";
				display: block;
				left: 9px;
				padding-top: 1px;
				position: absolute;
				right: 9px;
				transition: all 300ms;
				top: 10px;
			}
		}

		&::after {
			@media (--xs) {
				top: auto;
				bottom: 10px;
			}
		}

		span {
			@media (--xs) {
				background: #CC001B;
				overflow: hidden;
				text-indent: -500px;
				display: block;
				left: 9px;
				height: 1px;
				margin-top: 16px;
				position: absolute;
				right: 9px;
				top: 0;
			}
		}

		&.open {
			border-color: #666;

			&::before {
				@media (--xs) {
					background: #666;
					left: 10px;
					margin: 0;
					top: 49%;
					transform: rotate(-135deg);
				}
			}

			&::after {
				@media (--xs) {
					background: #666;
					bottom: 49%;
					left: 10px;
					margin: 0;
					transform: rotate(135deg);
				}
			}

			span {
				@media (--xs) {
					display: none;
				}
			}
		}
	}
}

// stylelint-disable
// 🐲 baserCMS用管理ツールバー
//
#ToolBar {
	opacity: 0.2;
	transition: 300ms;
	&:hover {
		opacity: 1;
	}
}
// stylelint-enable
