.c-nav-sitemap {
	background-color: $secondary-color-darker;

	%list {
		margin: 0;
	}

	%item {
		display: block;
		margin: 0;
		padding: 0;
	}

	a {
		color: $lightest-color;
		display: block;
	}

	&__body {
		@mixin flexible-width-body;
		background-color: $secondary-color-darker;
		margin: 0 auto;
		overflow: hidden;
	}

	ul[data-sitemap-level="1"] {
		@extend %list;
		column-count: 4;
		padding: 0;

		@media (--xs) {
			column-count: 1;
		}

		@media (--sm) {
			column-count: 2;
		}

		@media (--md) {
			column-count: 3;
		}
	}

	li[data-sitemap-level="1"] {
		@extend %item;
		break-inside: avoid-column;
		display: inline-block; // 💉 for columns item technic
		margin: 0 0 1em;
		width: 100%; // 💉 for columns item technic
	}

	ul[data-sitemap-level="2"] {
		@extend %list;
		padding: 0 0 0 1em;
	}

	li[data-sitemap-level="2"] {
		@extend %item;
		margin: 0 0 .5em;
	}

	ul[data-sitemap-level="3"] {
		@extend %list;
		padding: 0 0 0 1em;
	}

	li[data-sitemap-level="3"] {
		@extend %item;
	}
}
