.c-title-page {
	h1 {
		text-align: center;
		background: url(../img/common/bg-title-page.png) center top;
		background-size: 100% auto;
		color: #222;
		font-size: 40px;
		padding: 49px 0 96px;
		letter-spacing: 3.8px;
		margin: 0;
		line-height: 1.55;
		font-family: $font-family-mincho;
		font-weight: normal;
		font-feature-settings : "palt";
		@media (--xs) {
			background-position: left top;
      background-size: 600px auto;
			font-size: 20px;
			padding: 20px 0;
			letter-spacing: 2px;
			margin: 0 auto 30px;
		}

		span {
			display: block;
			font-size: 12px;
			color: #E90D2A;
			letter-spacing: 1px;
			font-family: $font-family-eng;
			@media (--xs) {
				font-size: 10px;
			}
		}
	}
}
