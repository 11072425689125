.c-nav-breadcrumb {
	width: 1000px;
	padding: 20px 50px;
	background: #FFF;
	margin: -60px auto 44px;
	font-size: 12px;
	letter-spacing: .2px;
	@media (--xs) {
		display: none;
	}

	ol {
		margin: 0;
		padding: 0;
	}

	a {
		color: #E90D2A;
		display: inline-block;
		text-decoration: none;
	}

	&__item {
		display: inline-block;
		margin: 0;
		padding: 0;
	}

	&__separetor {
		display: inline-block;

		&::after {
			content: "/";
			color: #E4E4E4;
			margin: 0 9px;
			vertical-align: -1px;
		}
	}

	&__item[data-breadcrumb="home"] {
		a {
			background: url("../img/common/icon-home.png") no-repeat left 5px;
			padding-left: 18px;
		}
	}

	&__item[data-breadcrumb="current"] {
		a {
			color: inherit;
			text-decoration: none;
		}
	}
}
