.c-footer {
	position: relative;
	min-width: 1000px;

	@media (--xs) {
		min-width: 0;
	}

	&::before {
		content: "";
		width: 216px;
		height: 167px;
		display: block;
		background: url(../img/common/pict-footer-pc.png) no-repeat;
		position: absolute;
		bottom: 0;
		right: 0;
		@media (--xs) {
			width: 100px;
			height: 83.5px;
			background: url(../img/common/pict-footer-sp.png) no-repeat;
			background-size: 100% auto;
		}
	}

	&__body {
		margin: 0 auto;
		background: #333;
		padding-bottom: 76px;
		min-width: 1000px;
		@media (--xs) {
			min-width: 0;
			padding: 27px 6.25% 84px;
		}
	}

	&__sitemap {
		width: 998px;
		margin: 0 auto;
		padding: 45px 0 0;
		overflow: hidden;
		position: relative;
		font-family: $font-family-mincho;
		@media (--xs) {
			display: none;
		}

		ul {
			float: left;
			width: 272px;
			margin: 0;
			padding: 0;
			font-size: 16px;
			list-style: none;
			@media (--xs) {
				width: auto;
				float: none;
				font-size: 15px;
			}

			&:nth-child(2) {
				width: 442px;
				@media (--xs) {
					width: auto;
				}
			}

			&:nth-child(3) {
				margin-top: 2px;
				@media (--xs) {
					margin-top: 0;
				}

				li {
					margin-bottom: 12px;
					line-height: 1.4;
					@media (--xs) {
						margin-bottom: 0;
						line-height: inherit;
						&:last-child {
							border: none;
						}
					}

					a {
						color: #FFF;
						font-family: $font-family-primary;
						font-weight: normal;
						font-size: 14px;
						background: url("../img/common/icon-footer-02-pc.png") no-repeat left 8px;
						@media (--xs) {
							font-size: 15px;
							font-weight: bold;
							background: url("../img/common/icon-footer-02-sp.png") no-repeat left 21px;
							background-size: 11.5px auto;
							padding: 11px 0 11px 17px;
						}
					}
				}
			}

			li {
				margin-bottom: 20px;
				@media (--xs) {
					border-bottom: solid 1px #FFF;
					margin-bottom: 1px;
				}

				a {
					color: #FFF;
					text-decoration: none;
					padding: 0 0 0 18px;
					background: url("../img/common/icon-footer-01-pc.png") no-repeat left 6px;
					-webkit-transition: .3s;
					transition: .3s;
					@media (--xs) {
						display: block;
						background: url("../img/common/icon-footer-01-sp.png") no-repeat left 22px;
						background-size: 11.5px auto;
						padding: 12px 0 12px 17px;
						letter-spacing: -.8px;
					}

					&:hover {
						opacity: .5;
					}
				}

				li {
					margin-bottom: 2px;
					line-height: 1.4;

					a {
						color: #FFF;
						font-family: $font-family-primary;
						font-weight: normal;
						font-size: 14px;
						background: url("../img/common/icon-footer-02-pc.png") no-repeat left 8px;
					}
				}

				ul {
					padding-left: 18px;
					float: none;
					width: auto;
					margin-top: 7px;
					@media (--xs) {
						display: none;
					}
				}
			}
		}
	}

	&__group {
		width: 1000px;
		margin: 23px auto 0;
		padding: 9px 0 8px;
		background: #222;
		color: #FFF;
		overflow: hidden;
		position: relative;
		@media (--xs) {
			width: auto;
			margin: -2px auto 0;
			padding: 0 6.25% 4px;
		}

		> div {
			text-align: center;
			font-family: YuGothic, "游ゴシック", Meiryo, "メイリオ", "MS PGothic", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "ＭＳ Ｐゴシック", sans-serif;
			width: 234px;
			font-size: 18px;
			font-weight: bold;
			float: left;
			padding: 40px 0 0;
			@media (--xs) {
				width: auto;
				font-size: 18px;
				float: none;
				padding: 20px 0 0;
			}
		}

		ul {
			list-style: none;
			overflow: hidden;
			padding: 0;
			@media (--xs) {
				margin-top: 18px;
				padding-bottom: 5px;
			}

			li {
				float: left;
				@media (--xs) {
					width: 200px;
					margin: 0 auto;
					float: none;
				}

				& + li {
					margin-left: 10px;
					@media (--xs) {
						margin: 10px auto 0;
					}
				}

				img {
					display: block;
					@media (--xs) {
						width: 100%;
					}
				}

				a {
					display: block;
					-webkit-transition: .3s;
					transition: .3s;

					&:hover {
						opacity: .5;
					}
				}
			}
		}
	}

	&__copyright {
		display: block;
		text-align: center;
		font-size: 11px;
		font-family: $font-family-eng;
		font-weight: bold;
		letter-spacing: -.4px;
		padding: 25px 0 23px;
		@media (--xs) {
			font-size: 7px;
			padding: 11px 2px 11px 0;
			letter-spacing: -.3px;
			position: relative;
		}
	}
}
