.c-nav-global {
	background: #333;
	z-index: 1100;
	padding: 22px 0 16px;
	min-width: 1000px;
	@media (--xs) {
		position: absolute;
		left: 0;
		right: 0;
		display: none;
		padding: 0;
		background: rgba(255, 255, 255, .8);
		z-index: 1400;
		min-width: 0;
	}

	&__body {
		@media (--xs) {
			background: #333;
			padding: 30px 0 0;
		}
	}

	&__list {
		text-align: center;
		margin: 0;
		padding: 0;
		@media (--xs) {
			width: 87.5%;
			margin: 0 auto;
			border-top: solid 1px #666;
		}
	}

	&__item,
	&__item-pc,
	&__item-sp {
		display: inline-block;
		position: relative;
		@media (--xs) {
			display: block;
		}

		&-sub,
		&-small {
			@media (--xs) {
				background: #222;
				font-size: 12px;
				margin-bottom: 16px;
				text-align: left;
				list-style: none;
				line-height: 1.3;
				overflow: hidden;
				padding: 9px 0 9px;

				a {
					color: $lightest-color;
					text-decoration: none;
					display: block;
					padding: 7px 0 4px 40px;
					background: url("../img/common/icon-gnav-02.png") no-repeat 22px 12px;
					background-size: 8px auto;
				}

				&-company {
					width: 50%;
					float: left;
				}
			}
		}

		&-small {
			@media (--xs) {
				background: none;
				margin-bottom: 0;
				padding: 13px 0 0;

				a {
					padding: 7px 0 4px 20px;
					background-position: 0 12px;
				}

				& + li {
					padding: 0;
				}
			}
		}

		> a {
			font-size: 16px;
			font-family: $font-family-mincho;
			color: $lightest-color;
			display: block;
			text-align: center;
			text-decoration: none;
			line-height: 1.4;
			-webkit-transition: .3s;
			transition: .3s;
			@media (--xs) {
				text-align: left;
				font-size: 13px;
				padding: 13px 20px 11px 21px;
				background: url("../img/common/icon-gnav-01.png") no-repeat left center;
				background-size: 9px auto;
				position: relative;
				letter-spacing: .6px;

				&::before {
					content: "";
					display: block;
					width: 6px;
					height: 10px;
					background: url("../img/common/icon-gnav-arrow-01.png") no-repeat;
					background-size: 100% auto;
					position: absolute;
					right: 0;
					top: 50%;
					margin-top: -5px;
				}
			}

			span {
				display: block;
				font-size: 10px;
				color: #EC2729;
				letter-spacing: .8px;
				font-family: $font-family-eng;
				@media (--xs) {
					display: inline-block;
					font-size: 8px;
					margin-left: 14px;
					vertical-align: middle;
				}
			}

			&:hover {
				opacity: .5;
			}

			&[target="_blank"] {
				padding-right: 30px;
				background: url("../img/common/icon-gaibu-01.png") no-repeat right 2px;
				@media (--xs) {
					background: url("../img/common/icon-gnav-01.png") no-repeat left center;
					background-size: 9px auto;
					padding: 13px 20px 11px 21px;
				}
			}
		}

		& + li {
			margin-left: 70px;
			padding-left: 70px;

			&:nth-child(2) {
				margin-left: 0;
				padding-left: 0;

				&::before {
					display: none;
				}
			}

			@media (--xs) {
				background: none;
				margin-left: 0;
				padding-left: 0;
				border-top: solid 1px #666;
			}

			&::before {
				content: "";
				width: 1px;
				height: 30px;
				display: block;
				background: #EC2729;
				position: absolute;
				left: 0;
				top: 3px;
				@media (--xs) {
					display: none;
				}
			}
		}

		&-sp,
		&-sub,
		&-small {
			display: none;
			@media (--xs) {
				display: block;
			}
		}
	}

	&__item-pc {
		@media (--xs) {
			display: none;
		}
	}

	&__spmenu {
		display: none;
		@media (--xs) {
			display: block;
			color: #FFF;
      font-family: $font-family-eng;
			font-size: 18px;
			text-align: center;
			padding: 7.5px 0 6.5px 1px;
			background: #000;
			letter-spacing: 1.4px;
			margin-top: 37px;
		}

		&::before {
			@media (--xs) {
				content: "";
				width: 11px;
				height: 11.5px;
				display: inline-block;
				vertical-align: 0;
				margin-right: 9px;
				background: url("../img/common/icon-gnav-close.png") no-repeat;
				background-size: 100% auto;
			}
		}
	}
}
